import React from 'react'
import { graphql, Link } from 'gatsby'

//Katex
import renderMathInElement from 'katex/dist/contrib/auto-render'
import 'katex/dist/katex.min.css'

// components
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import SEO from '../../components/SEO/seo'
import Layout from '../../components/Layout/layout'
import Section from '../../components/Section/section'
import GetStarted from  '../../components/GetStarted/getStarted'
import Breadcrumb from '../../components/Breadcrumb/breadcrumb'
import Article from '../../components/Blog/blogArticle'

//constants
import { NOTES_SLUG } from '../../utils/constants'

class BlogPage extends React.Component {
  _renderLatex = () => {
      renderMathInElement(this.katexField)
  }

  componentDidMount() {
      this._renderLatex()
  }

  componentDidUpdate() {
      this._renderLatex()
  }

  render({ data }){
      return <>
          <SEO title={`Exam Resource: ${data.contentfulTopicCategory.topicCategory}`}/>
          <div ref={ref => this.katexField = ref}>
              <Layout className={'qs-article-list'}>
                  <Section background={'bg-light-blue'} animation={false}>
                      <Col lg={12}>
                          <Breadcrumb linkClasses={'text-secondary'} hideHomeLink={true} listClasses={'mb-0 pb-0'}links={[
                              {
                                  name: `Exam ${NOTES_SLUG}`,
                                  destination: `/${NOTES_SLUG}`,
                                  last: false
                              },
                              {
                                  name: data.contentfulTopicCategory.topicCategory,
                                  destination: '',
                                  last: true
                              }
                          ]}/>
                          <h1 className='mb-2 mt-4 mt-lg-0 text-center text-sm-left qs-article-list__title' style={{textTransform: 'capitalize'}}>{data.contentfulTopicCategory.topicCategory}</h1>
                          <hr/>
                          <Row>
                              {
                                  data.allContentfulNotes.nodes.map((node) => (
                                      <Col as='div' className='col-6 col-md-6 col-lg-6 col-xl-4 mb-3' key={node.id}>
                                          <Link to={`/${NOTES_SLUG}/${node.slug}`} className='qs-article-item'>
                                              <Article
                                                  thumbnail={node.topic.topicImageSource}
                                                  title={node.title}
                                                  createdDate={node.createdAt}
                                                  topic={node.topic}
                                                  description={node.description.description}
                                                  slug={NOTES_SLUG}
                                                  
                                              />
                                          </Link>
                                      </Col>

                                  ))
                              }
                          </Row>
                      </Col>
                  </Section>
                  <GetStarted
                      background='bg-light-blue'
                      buttonVariant='primary'
                      secondaryButton={true}
                      secondaryButtonText={`Just answer questions and you will get an A - A* - guaranteed`}
                  />
              </Layout>  
          </div>

      </>
  }
}

export default BlogPage

export const query = graphql`
query LessonByTopicCategory($id: String) {
  allContentfulNotes(filter: {topicCategory: {id: {eq: $id}}}) {
    nodes {
      createdAt(formatString: "MMMM D, YYYY")
      description {
        description
      }
      metadata {
        tags {
          id
          name
        }
      }
      topic {
        topicImageSource
        topicName
        contentful_id
      }
      topicCategory {
        topicCategory
        contentful_id
      }
      title
      slug
    }
  }
  contentfulTopicCategory(id: {eq: $id}){
		topicCategory
  }
}

`
